<template>
  <div class="ccr">
    <h3 class="mt-2 mb-4">Consumer Confidence Report Upload</h3>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group label="Your Name" label-for="ccr-name">
        <b-form-input
          id="ccr-name"
          v-model="ccr_name"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Your Email Address" label-for="ccr-email">
        <b-form-input
          id="ccr-email"
          v-model="ccr_email"
          type="email"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="County" label-for="county">
        <b-form-select
          id="county"
          @change="onChangeCounty()"
          v-model="county"
          :options="county_options"
        ></b-form-select>
      </b-form-group>
      <b-form-group v-if="county" label="Public Water System" label-for="pws">
        <b-form-select
          id="pws"
          v-model="pws"
          :options="pws_options"
        ></b-form-select>
      </b-form-group>
    <b-form-group label="CCR Report" label-for="ccr-report">
      <b-form-file
        id="ccr-report"
        v-model="ccr_report"
        placeholder="Choose a file or drop it here."
        drop-placeholder="Drop file here ..."
      ></b-form-file>
    </b-form-group>
      <b-button type="submit" variant="primary">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>

    <b-modal @shown="onShowPDFModal" hide-header ok-only ok-title="Done" id="modal-pdf" size="xl" title="View PDF">
      <div id="mypdf" class="w-100" style="height: 80vh;"></div>
    </b-modal>
  </div>
</template>
<script>
import PDFObject from 'pdfobject'
export default {
  name: "CCR",
  data() {
    return {
      ccr_report: null,
      ccr_name: null,
      ccr_email: null,
      county: null,
      pws: null,
      pws_data: null,
      pdf_href: null,
      ccr_file_list: [],
      county_options: [
        { text: "Select a County", value: null },
        { text: "01-ADAMS", value: "ADAMS"},
        { text: "02-ALCORN", value: "ALCORN"},
        { text: "03-AMITE", value: "AMITE"},
        { text: "04-ATTALA", value: "ATTALA"},
        { text: "05-BENTON", value: "BENTON"},
        { text: "06-BOLIVAR", value: "BOLIVAR"},
        { text: "07-CALHOUN", value: "CALHOUN"},
        { text: "08-CARROLL", value: "CARROLL"},
        { text: "09-CHICKASAW", value: "CHICKASAW"},
        { text: "10-CHOCTAW", value: "CHOCTAW"},
        { text: "11-CLAIBORNE", value: "CLAIBORNE"},
        { text: "12-CLARKE", value: "CLARKE"},
        { text: "13-CLAY", value: "CLAY"},
        { text: "14-COAHOMA", value: "COAHOMA"},
        { text: "15-COPIAH", value: "COPIAH"},
        { text: "16-COVINGTON", value: "COVINGTON"},
        { text: "17-DESOTO", value: "DESOTO"},
        { text: "18-FORREST", value: "FORREST"},
        { text: "19-FRANKLIN", value: "FRANKLIN"},
        { text: "20-GEORGE", value: "GEORGE"},
        { text: "21-GREENE", value: "GREENE"},
        { text: "22-GRENADA", value: "GRENADA"},
        { text: "23-HANCOCK", value: "HANCOCK"},
        { text: "24-HARRISON", value: "HARRISON"},
        { text: "25-HINDS", value: "HINDS"},
        { text: "26-HOLMES", value: "HOLMES"},
        { text: "27-HUMPHREYS", value: "HUMPHREYS"},
        { text: "28-ISSAQUENA", value: "ISSAQUENA"},
        { text: "29-ITAWAMBA", value: "ITAWAMBA"},
        { text: "30-JACKSON", value: "JACKSON"},
        { text: "31-JASPER", value: "JASPER"},
        { text: "32-JEFFERSON", value: "JEFFERSON"},
        { text: "33-JEFF DAVIS", value: "JEFF DAVIS"},
        { text: "34-JONES", value: "JONES"},
        { text: "35-KEMPER", value: "KEMPER"},
        { text: "36-LAFAYETTE", value: "LAFAYETTE"},
        { text: "37-LAMAR", value: "LAMAR"},
        { text: "38-LAUDERDALE", value: "LAUDERDALE"},
        { text: "39-LAWRENCE", value: "LAWRENCE"},
        { text: "40-LEAKE", value: "LEAKE"},
        { text: "41-LEE", value: "LEE"},
        { text: "42-LEFLORE", value: "LEFLORE"},
        { text: "43-LINCOLN", value: "LINCOLN"},
        { text: "44-LOWNDES", value: "LOWNDES"},
        { text: "45-MADISON", value: "MADISON"},
        { text: "46-MARION", value: "MARION"},
        { text: "47-MARSHALL", value: "MARSHALL"},
        { text: "48-MONROE", value: "MONROE"},
        { text: "49-MONTGOMERY", value: "MONTGOMERY"},
        { text: "50-NESHOBA", value: "NESHOBA"},
        { text: "51-NEWTON", value: "NEWTON"},
        { text: "52-NOXUBEE", value: "NOXUBEE"},
        { text: "53-OKTIBBEHA", value: "OKTIBBEHA"},
        { text: "54-PANOLA", value: "PANOLA"},
        { text: "55-PEARL RIVER", value: "PEARL RIVER"},
        { text: "56-PERRY", value: "PERRY"},
        { text: "57-PIKE", value: "PIKE"},
        { text: "58-PONTOTOC", value: "PONTOTOC"},
        { text: "59-PRENTISS", value: "PRENTISS"},
        { text: "60-QUITMAN", value: "QUITMAN"},
        { text: "61-RANKIN", value: "RANKIN"},
        { text: "62-SCOTT", value: "SCOTT"},
        { text: "63-SHARKEY", value: "SHARKEY"},
        { text: "64-SIMPSON", value: "SIMPSON"},
        { text: "65-SMITH", value: "SMITH"},
        { text: "66-STONE", value: "STONE"},
        { text: "67-SUNFLOWER", value: "SUNFLOWER"},
        { text: "68-TALLAHATCHIE", value: "TALLAHATCHIE"},
        { text: "69-TATE", value: "TATE"},
        { text: "70-TIPPAH", value: "TIPPAH"},
        { text: "71-TISHOMINGO", value: "TISHOMINGO"},
        { text: "72-TUNICA", value: "TUNICA"},
        { text: "73-UNION", value: "UNION"},
        { text: "74-WALTHALL", value: "WALTHALL"},
        { text: "75-WARREN", value: "WARREN"},
        { text: "76-WASHINGTON", value: "WASHINGTON"},
        { text: "77-WAYNE", value: "WAYNE"},
        { text: "78-WEBSTER", value: "WEBSTER"},
        { text: "79-WILKINSON", value: "WILKINSON"},
        { text: "80-WINSTON", value: "WINSTON"},
        { text: "81-YALOBUSHA", value: "YALOBUSHA"},
        { text: "82-YAZOO", value: "YAZOO"},
      ]
    }
  },
  methods: {
    onSubmit(event) {
      // submit
      event.preventDefault()
      let ccr_name = this.ccr_name
      let ccr_email = this.ccr_email
      let pws_id = this.pws_id
      let ccr_report = this.ccr_report
      let form_data = new FormData()
      form_data.append('ccr_name', ccr_name)
      form_data.append('ccr_email', ccr_email)
      form_data.append('ccr_pws_id', pws_id)
      form_data.append('ccr_report', ccr_report)
      const path = process.env.VUE_APP_API_URI + "/upload-ccr"
      return this.axios
        .post(path, form_data, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((res) => {
          console.log(res)
          if (res.data) {
            console.log("got response")
          } else {
            console.log("no response")
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    onReset() {
      // pass
      this.ccr_name = null
      this.ccr_email = null
      this.county = null
      this.pws = null
      this.pws_data = null
      this.ccr_report = null
    },
    showPDF(pws_id, filename) {
      //console.log(evt)
      console.log(pws_id)
      console.log(filename)
      this.pdf_href =  '/ccr_data/' + pws_id + '/' + filename
      console.log(this.pdf_href)
      this.$bvModal.show('modal-pdf')
    },
    onShowPDFModal(e) {
      console.log(e)
      PDFObject.embed(this.pdf_href, '#mypdf')
    },
    onChangeCounty() {
      this.pws = null
      this.pws_data = null
      this.ccr_file_list = []
      this.pdf_href = null
      this.fetchPWSData()
    },
    fetchPWSData() {
      this.pws = null
      if (!this.county) {
        console.log("County is not set.")
        return
      }
      console.log("called fetchPWSData")
      const path = process.env.VUE_APP_API_URI + "/get-pws-for-county"
      console.log(path)
      return this.axios
        .get(path, { params: { county_name: this.county } })
        .then((res) => {
          console.log(res)
          if (res.data) {
            this.pws_data = res.data
            console.log("got data")
          } else {
            console.log("record data fetch failed")
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  computed: {
    pws_options: function() {
      let options = [{ value: null, text: "Select a Water System" }]
      console.log("Computing for:")
      console.log(this.pws_data)
      console.log(this.county)
      if (this.pws_data) {
        for (let obj of this.pws_data) {
          let display = obj.pws_id + " - " + obj.pws_name
          options.push(display)
          console.log(obj)
        }
      }
      console.log(options)
      return options
    },
    pws_id: function() {
      if (this.pws) {
        console.log(this.pws.substring(0, 7))
        return this.pws.substring(0, 7)
      } else {
        return null
      }
    }
  }
}
</script>
